<template>
  <div :class="'toast toast-' + data.type" style="display: block" @click="click" v-on:mouseover="onMouseOver" v-on:mouseout="onMouseOut" >
    <div class="toast-title" v-html="data.title"></div>
    <div class="toast-message" v-html="data.msg"></div>
  </div>
</template>

<script>
export default {
  name: 'vue-toastr',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  created () {
    if (this.data.timeout) {
      this.setTimeout()
    }
  },
  methods: {
    // Enter Hover
    onMouseOver () {
      if (this.data.onMouseOver) {
        this.data.onMouseOver()
      }
      if (this.data.closeOnHover && this.data.intervalId) {
        clearInterval(this.data.intervalId)
      }
    },
    // Leave Hover
    onMouseOut () {
      if (this.data.onMouseOut) {
        this.data.onMouseOut()
      }
      if (this.data.closeOnHover) {
        this.setTimeout()
      }
    },
    // Set timeout to close
    setTimeout () {
      this.data.intervalId = setTimeout(() => {
        this.close()
      }, this.data.timeout)
    },
    // Clicked Toast
    click () {
      if (this.data.onClick) {
        this.data.onClick()
      }

      if (this.data.clickClose) {
        this.close()
      }
    },
    // Close Toast
    close () {
      // if toast not manually closed
      if (this.$parent !== null) {
        this.$parent.close(this.data)
      }
    },
  },
}
</script>
