<template>
  <div class="vue-flash-messages">
    <flash-messages />
  </div>
</template>

<script>
  import { each, includes } from 'lodash'
  import { WindowVariables } from 'Utils'
  import FlashMessages from 'Components/flash_messages/FlashMessages.vue'
  import FlashMessageService from 'Services/FlashMessages'

  export default {
    name: 'flash-alert-app',
    components: {
      FlashMessages,
    },
    mounted () {
      if (WindowVariables.flashMessages && WindowVariables.flashMessages.length > 0) {
        each(WindowVariables.flashMessages, msg => {
          // split on ':' to allow for more sophisticated options to be passed through
          let opts = msg[0].split(':')
          let method = opts[0]
          opts.splice(0, 1)
          let optsDict = {}

          if (opts.length > 0) {
            if (includes(opts, 'indefinite')) {
              optsDict.timeout = 0
              optsDict.clickClose = false
            }

            // put any more options you want to pass through here
          }

          FlashMessageService[method](msg[1], null, optsDict)
        })
      }
    },
  }
</script>
